import React, { useContext } from 'react'
import withRouter from 'react-router-dom/withRouter'

const ApiContext = React.createContext(null)

export const ApiProvider = withRouter(function ({ children, api, location, history }) {
  api.connector.onNavigateTo = navigateTo

  function navigateTo(href) {
    if (!href) return
    const isSilent = href.startsWith('$currentSilently')
    const isReload = href.startsWith('$current')
    const isBack = href === '$back'
    const isParent = href === '$parent'
    const isPopup = href.startsWith('#')

    const { state: { code: currentCode } = {} } = location
    let code = currentCode

    if (isBack) {
      return window.history.back()
    }

    if (isParent) {
      const hasHashSymbol = window.location.href.includes('#')
      if (hasHashSymbol) {
        return (window.location = window.location.href.substring(
          0,
          window.location.pathname.lastIndexOf('#'),
        ))
      } else {
        const newPathName = window.location.pathname.substring(
          0,
          window.location.pathname.lastIndexOf('/'),
        )
        return (window.location = window.location.origin + newPathName)
      }
    }

    if (/^http/.test(href)) {
      return (window.location.href = href)
    }

    if (isSilent || isReload) {
      code = parseInt((Math.random() * 99999).toFixed(0), 10).toString(36)

      let [, search, hash] = /(\?.+?)?(#.*?)?$$/.exec(href) || []

      href =
        window.location.pathname +
        (search || window.location.search) +
        (hash || window.location.hash)
    } else if (isPopup) {
      href = window.location.pathname + window.location.search + href
    }

    history.push(href, { code, isSilent })
  }

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
})

export const withApi = Component => props => {
  return <ApiContext.Consumer>{api => <Component {...props} api={api} />}</ApiContext.Consumer>
}

export const useApi = () => useContext(ApiContext)
